import React, { Component, Fragment } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import isAuthenticated from '../common/util/authWrapper';
import Navbar from '../menu/navbar/Navbar';
import Login from '../menu/login/Login';
import NotFound from '../menu/login/NotFound';
import Register1 from '../menu/login/Register1';
import Register2 from '../menu/login/Register2';
import Register3 from '../menu/login/Register3';
import AuthDashboard from '../menu/login/AuthDashboard';
// Student Menu
import Dashboard from '../menu/students-menu/dashboard/Index';
import Profil from '../menu/students-menu/profil/Index';
import Absensi from '../menu/students-menu/presensi/Index';
import Jadwal from '../menu/students-menu/jadwal/Index';
// import Pembelajaran from '../menu/students-menu/pembelajaran/Index';
import MateriView from '../menu/students-menu/pembelajaran/ViewMateri';
import TugasView from '../menu/students-menu/pembelajaran/ViewTugas';
import LatihanView from '../menu/students-menu/pembelajaran/ViewLatihan';
import LatihanResult from '../menu/students-menu/pembelajaran/ViewLatihanResult';
import LatihanReview from '../menu/students-menu/pembelajaran/ViewLatihanReview';
import TesView from '../menu/students-menu/pembelajaran/ViewTes';
import Rapormid from '../menu/students-menu/rapor-mid/Index';
import RapormidDetail from '../menu/students-menu/rapor-mid/View';
import Raporsem from '../menu/students-menu/rapor-sem/Index';
import RaporsemDetail from '../menu/students-menu/rapor-sem/View';
import HasilTestPsikologis from '../menu/students-menu/hasil-test-psikologis/Index';
import Raporkolab from '../menu/students-menu/rapor-kolab/Index';
import Progressreport from '../menu/students-menu/progress-report/Index';
import ProgressreportDetail from '../menu/students-menu/progress-report/View';
import Raporsemtk from '../menu/students-menu/rapor-sem-tk/Index';
import RaporsemtkDetail from '../menu/students-menu/rapor-sem-tk/View';
// import Accounts from '../menu/students-menu/account-statement/Index';
// import AccountsDetail from '../menu/students-menu/account-statement/View';
import Accounts2 from '../menu/students-menu/account-statement/Index2';
import AccountsDetail2 from '../menu/students-menu/account-statement/View2';
import Accounts3 from '../menu/students-menu/account-statement/Index3';
import AccountsDetail3 from '../menu/students-menu/account-statement/View3';
import Accounts4 from '../menu/students-menu/account-statement/Index4';
import AccountsDetail4 from '../menu/students-menu/account-statement/View4';
import Agenda from '../menu/students-menu/agenda/Index';
import Kalender from '../menu/students-menu/kalender-akademik/Index';
import Pesan from '../menu/students-menu/pesan-pribadi/Index';
import PengaturanAkun from '../menu/students-menu/pengaturan-akun/Index';
// Student Menu End
import Test from '../menu/tests/Index';
import PrintPage from '../menu/tests/PrintPage';
import ModalManager from '../menu/modals/ModalManager';
import './App.css';

class App extends Component {
  render() {
    return (
      <Fragment>
        <ModalManager />
        <Fragment>
          <Navbar />
          <Switch key={this.props.location.key}>
            <Route exact path='/' component={Login} />
            <Route exact path='/login' component={Login} />
            <Route exact path='/register' component={Register1} />
            <Route exact path='/register-2' component={Register2} />
            <Route exact path='/register-3' component={Register3} />
            <Route exact path='/authdashboard' component={AuthDashboard} />
            {/* Students Menu */}
            <Route
              exact
              path='/dashboard'
              component={isAuthenticated(Dashboard)}
            />
            <Route exact path='/profil' component={isAuthenticated(Profil)} />
            <Route exact path='/absensi' component={isAuthenticated(Absensi)} />
            <Route
              exact
              path='/pembelajaran/materi/:id'
              component={isAuthenticated(MateriView)}
            />
            <Route
              exact
              path='/pembelajaran/tugas/:id'
              component={isAuthenticated(TugasView)}
            />
            <Route
              exact
              path='/pembelajaran/latihan/:id'
              component={isAuthenticated(LatihanView)}
            />
            <Route
              exact
              path='/pembelajaran/latihan/result/:id'
              component={isAuthenticated(LatihanResult)}
            />
            <Route
              exact
              path='/pembelajaran/latihan/review/:id'
              component={isAuthenticated(LatihanReview)}
            />
            <Route
              exact
              path='/pembelajaran/tes/:id'
              component={isAuthenticated(TesView)}
            />
            {/* <Route
              path='/pembelajaran'
              component={isAuthenticated(Pembelajaran)}
            /> */}
            <Route exact path='/jadwal' component={isAuthenticated(Jadwal)} />
            <Route
              exact
              path='/rapor-mid'
              component={isAuthenticated(Rapormid)}
            />
            <Route
              exact
              path='/rapor-mid/:id'
              component={isAuthenticated(RapormidDetail)}
            />
            <Route
              exact
              path='/rapor-sem'
              component={isAuthenticated(Raporsem)}
            />
            <Route
              exact
              path='/rapor-sem/:id'
              component={isAuthenticated(RaporsemDetail)}
            />
            <Route
              exact
              path='/rapor-kolab'
              component={isAuthenticated(Raporkolab)}
            />
            <Route
              exact
              path='/hasil-test-psikologis'
              component={isAuthenticated(HasilTestPsikologis)}
            />
            <Route
              exact
              path='/progress-report'
              component={isAuthenticated(Progressreport)}
            />
            <Route
              exact
              path='/progress-report/:id'
              component={isAuthenticated(ProgressreportDetail)}
            />
            <Route
              exact
              path='/rapor-sem-tk'
              component={isAuthenticated(Raporsemtk)}
            />
            <Route
              exact
              path='/rapor-sem-tk/:id'
              component={isAuthenticated(RaporsemtkDetail)}
            />
            <Route
              exact
              path='/account-statement'
              component={isAuthenticated(Accounts3)}
            />
            <Route
              exact
              path='/account-statement/:id'
              component={isAuthenticated(AccountsDetail3)}
            />
            <Route
              exact
              path='/account-statement4'
              component={isAuthenticated(Accounts4)}
            />
            <Route
              exact
              path='/account-statement4/:id'
              component={isAuthenticated(AccountsDetail4)}
            />
            <Route
              exact
              path='/account-statement2'
              component={isAuthenticated(Accounts2)}
            />
            <Route
              exact
              path='/account-statement2/:id'
              component={isAuthenticated(AccountsDetail2)}
            />
            <Route
              exact
              path='/account-statement3'
              component={isAuthenticated(Accounts3)}
            />
            <Route
              exact
              path='/account-statement3/:id'
              component={isAuthenticated(AccountsDetail3)}
            />
            <Route exact path='/agenda' component={isAuthenticated(Agenda)} />
            <Route
              exact
              path='/kalender-akademik'
              component={isAuthenticated(Kalender)}
            />
            <Route
              exact
              path='/pesan-pribadi'
              component={isAuthenticated(Pesan)}
            />
            <Route
              exact
              path='/pengaturan-akun'
              component={isAuthenticated(PengaturanAkun)}
            />
            {/* Students Menu End */}
            <Route path='/test' component={Test} />
            <Route path='/test/printpage' component={PrintPage} />
            <Route path='/*' component={NotFound} />
          </Switch>
        </Fragment>
      </Fragment>
    );
  }
}

export default withRouter(App);
